.mini-sab {
	position: absolute;
	width: 100%;
	z-index: 3;
	transform: translateY(-200px);
	@include media-breakpoint-up(sm) {
		transform: translateY(-140px);
	}
	@include media-breakpoint-up(lg) {
		transform: translateY(-120px);
	}
	@include media-breakpoint-up(xl) {
		transform: translateY(-80px);
	}
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		.container-holder {
			padding: 15px 0;
			background: transparent;
			color: white;
		
			@include media-breakpoint-up(lg) {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
}
&.home {
	.mini-sab {
		transform: translateY(-290px);
		@include media-breakpoint-up(lg) {
			transform: translateY(-180px);
		}
	}
}