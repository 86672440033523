.footer {
	position: relative;
	margin-top: 35px;

	&::before {
		content: "";
		position: absolute;
		top: -38px;
		width: 100%;
		height: 25px;
		z-index: 2;
		background: url("/images/green-path.svg") top/cover;
		@include media-breakpoint-up(lg) {
			top: -40px;
			height: 40px;
		}
	}
}

> footer > section {
	background-color: $primary;

	&.footer-socket {
		padding: ($grid-gutter-width * 2.16) 0 ($grid-gutter-width / 1.2);
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: -24px;
			width: 100%;
			height: 25px;
			background: url("/images/grey-path.svg") top/cover;
		}

		.container-one-column {
			.container-holder {
				text-align: center;

				.column {
					.page-block.logo {
						img {
							width: 156px;
							height: 156px;
						}
					}

					.footer-text {
						margin-top: $grid-gutter-width * 1.16;

						p {
							color: white;
							max-width: 115px;
							margin: auto;
						}
					}

					ul.vocabulary-term-list {
						margin: ($grid-gutter-width * 1.16) auto auto;
						justify-content: center;

						li {
							border: 2px solid white;
							border-radius: 3px;
							width: 246px;
							padding: ($grid-gutter-width / 10) 0 ($grid-gutter-width / 10) ($grid-gutter-width / 1.5);
							margin: 0 ($grid-gutter-width / 2) ($grid-gutter-width / 2) 0;

							a, span {
								color: white;
								text-decoration: none;
								font: {
									family: $font-family-smooch-sans;
									size: $font-size-base * 1.25;
									weight: $font-weight-bold;
								}

								&::before {
									margin-right: 10px;
									font: {
										family: $font-awesome;
										size: $font-size-base / 1.066;
										weight: $font-weight-base;
									}
								}

								&:hover {
									opacity: 0.7;
									cursor: pointer;
								}
							}

							&:first-of-type {
								span {
									&::before {
										content: "\F0E0";
									}
								}
							}

							&:nth-of-type(2) {
								a {
									&::before {
										content: "\F095";
									}
								}
							}

							&:last-of-type {
								span {
									&::before {
										content: "\F3CF";
									}
								}
							}
						}
					}

					.footer-link {
						margin: ($grid-gutter-width * 2) auto auto;
						@include media-breakpoint-up(sm) {
							margin: ($grid-gutter-width * 3) auto auto;
						}

						.list {
							&-item {
								margin: 0 ($grid-gutter-width / 1.2) 0 0;

								.link {
									color: white;
									font-size: $font-size-base_xs;
									text-decoration: none;

									&:hover {
										text-decoration: underline;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

&.service-overview, &.accommodation-detail, &.contact, &.accommodation-book {
	.footer {
		&::before {
			background: url("/images/wave-white-top.svg") top/cover;
			transform: scale(-1);
		}
	}
}
