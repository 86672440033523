.btn {
    display: block !important;
    align-items: center;
    padding: ($grid-gutter-width / 10) ($grid-gutter-width * 1.33) ($grid-gutter-width / 10) ($grid-gutter-width / 1.2);
    font-family: $font-family-smooch-sans !important;
    font-size: $font-size-base * 1.25 !important;
    font-weight: $font-weight-bold;
    border-radius: 3px;
    border-width: 2px;
    color: $white;
    text-decoration: none !important;
    transition: 0.3s;
    width: max-content;
    position: relative;
    &::after {
        content: "\f0a9";
        position: absolute;
        top: 5px;
        margin-left: $grid-gutter-width / 3;
        transition: 0.3s;
        font: {
            family: $font-awesome;
            size: $font-size-base * 1.0625;
            weight: $font-weight-base;
        }
    }
    /* btn-primary */
    &.btn-primary {
        background: transparent;
        border-color: $primary;
        color: $primary;
        &:hover {
            background: $primary;
            border-color: $primary;
            color: $white;
        }
    }

    /* btn-outline-primary */
    &.btn-outline-primary {
        background-color: $chardonnay;
        border-color: $chardonnay;
        color: $secondary !important;
        &:hover {
            background-color: darken($color: $chardonnay, $amount: 15%);
            border-color: $chardonnay !important;
        }
    }

    /* btn-secondary */
    &.btn-secondary {
        background-color: transparent;
        border: none;
        color: $primary;
        padding-left: 0;
        text-decoration: underline!important;
        &:hover {
            color: darken($color: $primary, $amount: 15%);
        }
    }

    /* btn-outline-secondary */
    &.btn-outline-secondary {
        background-color: transparent;
        border: none;
        color: $secondary;
        padding-left: 0;
        text-decoration: underline!important;
        &:hover {
            color: darken($color: $secondary, $amount: 15%);
        }
    }
}

.bg-dark {
    /* btn-primary */
    .btn-primary {
        background: transparent !important;
        border-color: $white !important;
        color: $white !important;
        &:hover {
            color: white;
            box-shadow: $box-shadow;
            background-color: transparent;
            border-color: white !important;
        }
    }
    .btn-outline-primary {
        color: $secondary !important;
        &:hover {
            color: $secondary !important;
            background-color: darken($color: $chardonnay, $amount: 15%);
        }
    }
    .btn-outline-secondary {
        color: $chardonnay !important;
        &:hover {
            color: darken($color: $chardonnay, $amount: 15%) !important;
        }
    }
}
/*  btn-back
    ========================================================================== */
.btn-back {
    display: flex;
    align-items: center;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    text-decoration: none;
    color: white;
    &:hover {
        color: white;
        font-weight: $font-weight-bold;
    }
    &::before {
        display: inline-block;
        content: "\f0a8" !important;
        font-size: $font-size-base * 1.0625;
        font-weight: $font-weight-base
    }
}

/*  btn-down
    ========================================================================== */
.btn-down {
    @extend .btn;
    @extend .btn-primary;
}
