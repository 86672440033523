.eyecatcher {
    @include media-breakpoint-up(xl) {
        margin-top: -($grid-gutter-width * 3.66);
    }
    &.large {
        @include media-breakpoint-down(sm) {
            min-height: 750px;
        }
        .owl-carousel {
            @include media-breakpoint-down(sm) {
                min-height: 750px;
            }
        }
        .owl-stage-outer {
            .owl-stage {
                .owl-item {
                    .item {
                        @include media-breakpoint-down(sm) {
                            min-height: 750px;
                        }
                        .owl-container {
                            .owl-caption {
                                margin-top: 40px;
                                @include media-breakpoint-up(sm) {
                                    margin-top: 0;
                                }
                                @include media-breakpoint-up(lg) {
                                    margin-top: 220px;
                                }
                               
                            }
                        }
                    }
                }
            }
        } 
    }
    .owl-stage-outer {
        .owl-stage {
            .owl-item {
                .item {
                    .owl-container {
                        .owl-caption {
                            margin-top: 0;
                            @include media-breakpoint-up(sm) {
                                margin-top: 0;
                            }
                            @include media-breakpoint-up(lg) {
                                margin-top: 20px;
                            }
                            .owl-title {
                                text-shadow: none;
                                font: {
                                    family: $font-family-smooch-sans;
                                    size: $font-size-base * 5;
                                    weight: $font-weight-bold;
                                }
                                @include media-breakpoint-down(sm) {
                                    line-height: 1;
                                }
                                @include media-breakpoint-up(xl) {
                                    font-size: $font-size-base * 7.5;
                                }
                            }
                            .owl-subtitle,.owl-description,.owl-btn {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .owl-nav,.owl-dots {
        display: none;
    }
    .home-banner-logo {
        img {
            position: absolute;
            width: 203px;
            height: 203px;
            top: 10%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            @include media-breakpoint-up(xl) {
                width: 297px;
                height: 297px;
            }
        }
    }
    a.go-down-arrow {
        background: url('/images/arrow-bg.svg') no-repeat !important;
        width: 100px;
        height: 90px;
        position: absolute !important;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
        border-color: transparent !important;
        box-shadow: none !important;
        transition: 0.3s;
        @include media-breakpoint-up(sm) {
            bottom: -70px;
        }
        &:hover {
            &::after {
                margin-top: 2px;
            }
        }
        &::after {
            position: relative !important;
            content: "\f0ab" !important;
            font-family: $font-awesome;
            font-weight: $font-weight-base !important;
            font-size: $font-size-base * 1.6875 !important;
            color: white;
            margin-top: -($grid-gutter-width / 1.5) !important;
            margin-left: $grid-gutter-width / 3 !important;
        }
    }
}
