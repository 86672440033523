.card {
	border: none;
	box-shadow: none;
	border-radius: 0;

	&-body {
		padding: ($grid-gutter-width) ($grid-gutter-width) ($grid-gutter-width * 3) !important;

		.card-caption {
			.card-title-link {
				position: absolute;
				bottom: 50%;
				margin-bottom: 30px;

				.card-title {
					color: white;
					text-shadow: 0 0 9px black;
					font: {
						family: $font-family-smooch-sans;
						size: $h4-font-size;
						weight: $font-weight-bold;
					}
					@include media-breakpoint-up(sm) {
						font-size: $h3-font-size;
					}
				}
			}

			.card-description {
				font-size: $font-size-base / 1.067;
				margin: ($grid-gutter-width / 2) 0 ($grid-gutter-width / 3);
			}
		}

		.card-buttons {
			.card-btn {
				position: relative;
				color: $primary;
				padding: 0;
				background: transparent;
				border: none;
				box-shadow: none;
				display: inline;
				text-align: left;
				text-decoration: none !important;
				font: {
					size: $font-size-base * 1.25;
					family: $font-family-smooch-sans;
					weight: $font-weight-bold;
				}

				&:hover {
					&::after {
						margin-left: $grid-gutter-width / 2;
					}
				}

				&::after {
					content: "\f0a9";
					position: static;
					margin-left: $grid-gutter-width / 3;
					transition: 0.3s;
					right: -10px;
					top: 0;
					transform: translate(100%);
					font: {
						family: $font-awesome;
						size: $font-size-base * 1.0625;
						weight: $font-weight-base;
					}
				}
			}
		}
	}

	.card-image {
		.card-image-link {
			.card-image-caption {
				bottom: auto;

				.card-image-label {
					position: absolute;
					top: 5px;
					font-weight: 600;
				}
			}
		}
	}
}

.collection.grid {
	.grid-items {
		.item {
			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}

			.card {
				box-shadow: $box-shadow;

				&-body {
					padding: ($grid-gutter-width) ($grid-gutter-width) ($grid-gutter-width * 1.66) !important;

					.card-price-value.forsale {
						.card-price-value {
							text-decoration: line-through;
							opacity: .7;
						}
					}

					.card-price.discount {
						.card-price-value {
							font-size: 1.5rem;
						}
					}
				}
			}
		}
	}
}
