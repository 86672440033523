.header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	@include media-breakpoint-up(xl) {
		position: fixed;
	}

	.container-one-column {
		.container-holder {
			.column {
				padding: 0 ($grid-gutter-width / 1.875);
				height: 45px;
				background-color: $secondary;
				position: relative;
				@media(max-width: 350px) {
					justify-content: flex-end;
				}

				&::after {
					content: "";
					position: absolute;
					bottom: -32px;
					left: 0;
					right: 0;
					width: 100vw;
					height: 50px;
					background: url("/images/header-bottom.svg") repeat-x bottom/cover;
					@include media-breakpoint-up(sm) {
						height: 110px;
						bottom: -38px;
					}
				}

				.menu-toggle {
					margin-top: -($grid-gutter-width / 3);
					@include media-breakpoint-up(xl) {
						margin-top: 0;
					}

					.navbar-toggler {
						display: block !important;
						position: relative;
						z-index: 3;
						color: white;

						&-label {
							margin-left: 10px;
							font: {
								family: $font-family-smooch-sans;
								size: $font-size-base * 1.25;
								weight: $font-weight-bold;
							}
						}

						&[aria-expanded="true"] {
							.navbar-toggler-icon {
								i {
									&::before {
										content: "\F00D";
									}
								}
							}
						}
					}
				}

				.side_panel.navbar-collapse.menu {
					position: fixed;
					left: 0;
					top: 0;
					width: 100vw;
					transform: translateX(-100vw);
					height: 100%;
					background: $secondary;
					transition: 0.25s;
					z-index: 2;
					text-align: center;
					padding: 5rem 2rem;

					&::after {
						content: "";
						position: fixed;
						bottom: 100px;
						left: 50%;
						transform: translateX(-50%);
						background: url("/images/sidebar_logo.svg");
						width: 80px;
						height: 80px;
						@media screen and(max-width: 375px) {
							bottom: 60px;
						}
						@include media-breakpoint-up(sm) {
							bottom: 20px;
						}
						@media screen and(min-width: 1600px) {
							bottom: 60px;
						}
					}

					@include media-breakpoint-up(md) {
						transform: translateX(-500px);
						width: 90%;
						max-width: 500px;
						&::before {
							content: "";
							display: none;
							opacity: 0;
							position: fixed;
							top: 0;
							left: 500px;
							right: 0;
							background: rgba($black, 0.5);
							bottom: 0;
							width: calc(100vw - 500px);
							transition: all 250ms;
						}
					}
					@include media-breakpoint-down(lg) {
						&.collapse {
							display: block;
						}
					}

					&.show {
						transform: translateX(0);
						transition: 0.25s ease-in-out !important;

						&::before {
							display: inline-block;
							opacity: 1;
						}
					}

					&.side_panel_opened {
						.nav-item {
							&:not(.active_side_panel) {
								display: block !important; //!important was needed to override base styles
							}
						}
					}

					.side-panel-close {
						display: none;
					}

					.navbar-nav {
						width: 100%;
						flex-direction: column;
						justify-content: center;
						height: 85%;
						overflow: auto;
						position: relative;

						> li {
							padding: ($grid-gutter-width / 3) 0;
							text-align: center;

							a {
								font-size: $font-size-base * 1.0625;
								font-weight: $font-weight-bold;
								color: $white;

								&:hover {
									text-decoration: underline;
								}
							}

							.dropdown-toggle {
								&::after {
									border: none;
								}
							}

							&.nav-item.show {
								ul.dropdown-menu.show {
									position: static !important;
									transform: none !important;
									margin: 0;
									padding: 0;
									border: none;
									background: none;
									width: 100%;
									text-align: center;

									.menu-side-panel-header {
										display: none;

										.go-back {
											position: relative;

											&::after {
												font-family: $font-awesome;
												font-size: $font-size-base * 1.5;
												font-weight: 400;
												border: none;
												position: absolute;
												top: 50%;
												content: "\F178" !important; // !important needed to override base css
												transform: translateY(-50%) rotate(180deg);
											}
										}
									}

									.nav-item {
										padding: 0;
										text-align: center;

										.nav-link {
											font-size: $font-size-base / 1.067;
											font-weight: $font-weight-base;
											color: $white;
											padding: ($grid-gutter-width / 3) 0;
										}
									}
								}
							}
						}
					}
				}

				.page-block.logo {
					display: flex;
					justify-content: center;
					flex-grow: 1;
					position: relative;
					z-index: 1;
					margin-top: 20px;

					&::after {
						content: "";
						position: absolute;
						bottom: -10px;
						left: 50%;
						transform: translateX(-50%);
						width: 100px;
						height: 90px;
						z-index: -1;
						background: url("/images/logo-bg.svg") repeat-x bottom/cover;
						@include media-breakpoint-up(xl) {
							bottom: -20px;
						}
					}
				}

				div.vocabulary-term-list {
					order: 2;
					@include media-breakpoint-up(xl) {
						order: unset;
					}
				}

				ul.vocabulary-term-list {
					position: relative;
					z-index: 1;

					@include media-breakpoint-down(lg) {
						margin-top: -($grid-gutter-width / 6);
						margin-right: -10px;
					}

					li {
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;
						height: 45px;
						padding: 0 10px;
						overflow: hidden;
						@include media-breakpoint-up(xl) {
							width: 155px;
							margin-top: $grid-gutter-width / 3;
						}

						a {
							text-decoration: none;

							&:hover {
								span {
									color: darken($color: $secondary, $amount: 15%);
								}
							}

							&::after {
								content: "";
								position: absolute;
								top: 0;
								left: 0;
								background: url("/images/zoek_boek-bg.svg");
								width: 155px;
								height: 45px;
								z-index: -1;
							}
						}

						span {
							font: {
								family: $font-family-smooch-sans;
								size: $font-size-base * 1.25;
								weight: $font-weight-bold;
							}
							color: $secondary;
							position: relative;
							z-index: 1;
						}

					}
				}
			}
		}
	}

	// responsive custom styling
	.page-block.menu-toggle {
		min-width: 0;
	}

	.page-block.logo {
		min-width: 0;
		margin: 0;
	}

	.page-block.site-switcher {
		margin: 0;
		flex-grow: 1;
	}

	// responsive mobile header order.
	.page-block.menu-toggle {
		order: 0;
	}

	.page-block.logo {
		order: 1;
	}

	.page-block.site-switcher {
		order: 2;
	}

	.page-block.menu {
		order: 3;
	}

	// Desktop resets
	@include media-breakpoint-up(xl) {
		.page-block.menu-toggle {
			order: unset;
		}
		.page-block.logo {
			order: unset;
		}
		.page-block.site-switcher {
			order: unset;
		}
		.page-block.menu {
			order: unset;
		}

		.page-block.site-switcher {
			display: flex;
			justify-content: flex-end;
			margin-left: 25px;
			flex-grow: 0;
		}
	}

	&.sticky {
		height: 45px;
		box-shadow: none;

		.container-one-column {
			.container-holder {
				.column {
					height: 45px;
					background-color: $secondary;
					position: relative;

					&::after {
						content: "";
						position: absolute;
						bottom: -38px;
						left: 0;
						right: 0;
						width: 100vw;
						height: 110px;
						background: url("/images/header-bottom.svg") repeat-x bottom/cover;
					}

					.page-block.logo {
						display: flex;
						visibility: visible;

						&::after {
							content: "";
							position: absolute;
							bottom: -20px;
							left: 50%;
							transform: translateX(-50%);
							width: 100px;
							height: 90px;
							z-index: -1;
							background: url("/images/logo-bg.svg") repeat-x bottom/cover;
						}
					}
				}
			}
		}
	}
}

&.home {
	.header {
		.container-one-column {
			.column {
				background-color: transparent;

				&::after {
					content: none;
				}

				.page-block.logo {
					visibility: hidden;
				}
			}
		}
	}
}
