/* 	bg-light/bg-dark
    ========================================================================== */
section.bg-light,
section.bg-dark {
	padding: ($grid-gutter-width * 2.66) 0;
}

/* 	lead-section
    ========================================================================== */
.lead-section {
	background-color: $hoki;
	position: relative;
	padding: ($grid-gutter-width * 2.66) 0;

	&::before {
		content: "";
		position: absolute;
		top: -38px;
		width: 100%;
		height: 25px;
		z-index: 2;
		background: url("/images/wave-hoki-top.svg") top/cover;
		@include media-breakpoint-up(lg) {
			top: -40px;
			height: 40px;
		}
	}

	&::after {
		content: "";
		position: absolute;
		bottom: -48px;
		width: 100%;
		height: 30px;
		z-index: 2;
		background: url("/images/wave-hoki-bottom.svg") top/cover;
		@include media-breakpoint-up(lg) {
			bottom: -35px;
			height: 40px;
		}
	}

	.container {
		&:first-of-type {
			&::before {
				content: "";
				position: absolute;
				top: -24px;
				left: 0;
				right: 0;
				width: 100vw;
				height: 25px;
				z-index: 1;
				background: url("/images/path-hoki.svg") top/cover;
			}
		}

		h5 {
			font-family: $font-family-base;
		}
	}

	.container-two-columns {
		&::after {
			content: "";
			position: absolute;
			bottom: -35px;
			left: 0;
			right: 0;
			width: 100%;
			height: 40px;
			background: url("/images/path-hoki.svg") bottom/cover;
			transform: scale(-1);
			@include media-breakpoint-up(lg) {
				bottom: -15px;
				height: 75px;
			}
		}

		.container-holder {
			.column {
				&.one {
					@include media-breakpoint-up(xl) {
						@include make-col(5);
					}

					.page-block {
						h1 {
							font: {
								family: $font-family-smooch-sans;
								size: $h2-font-size;
								weight: $font-weight-bold;
							}
						}

						h5 {
							font-size: $font-size-base * 1.0625 !important;
							font-family: $font-family-base;
							margin-bottom: $grid-gutter-width;
						}

						p {
							a {
								position: relative;
								font: {
									size: $font-size-base * 1.25;
									family: $font-family-smooch-sans;
									weight: $font-weight-bold;
								}

								&:hover {
									&::after {
										margin-left: $grid-gutter-width / 2;
									}
								}

								&::after {
									content: "\f0a9";
									position: absolute;
									top: 0;
									margin-left: $grid-gutter-width / 3;
									transition: 0.3s;
									font: {
										family: $font-awesome;
										size: $font-size-base * 1.0625;
										weight: $font-weight-base;
									}
								}
							}
						}

						h1,
						h5,
						p,
						a {
							color: white;
						}
					}
				}

				&.two {
					@include media-breakpoint-up(xl) {
						@include make-col(7);
					}
				}
			}
		}
	}

	.container-one-column {
		&::after {
			content: "";
			position: absolute;
			bottom: -35px;
			left: 0;
			right: 0;
			width: 100%;
			height: 40px;
			background: url("/images/path-hoki.svg") bottom/cover;
			transform: scale(-1);
			@include media-breakpoint-up(lg) {
				bottom: -15px;
				height: 75px;
			}
		}

		.container-holder {
			max-width: 810px;
			margin: 0 auto;
			text-align: center;

			.column {
				h1,
				h5,
				p {
					color: white;
				}

				h1 {
					font-size: $h3-font-size;
					@include media-breakpoint-up(lg) {
						font-size: $h2-font-size;
					}
				}

				h5 {
					font-size: $h6-font-size;
				}

				p {
					max-width: 500px;
					margin: auto;
					text-align: left;
				}
			}
		}
	}

	/* bundles */
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		.page-block {
			p {
			}
		}

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}

			.label {
			}
		}

		// assortiment
		.discount-price {
			.amount {
				font-size: $font-size-lg;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		.price {
			.amount {
				font-size: $font-size-lg;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		.former-price {

			.amount {
				font-size: $font-size-sm;
				text-decoration: line-through;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		.downloads {
			.label {
			}

			.file {
			}
		}
	}
}

/* 	image-slider
    ========================================================================== */
.image-slider {
	margin: ($grid-gutter-width * 2.66) 0 0;
	overflow: hidden;

	.container-one-column {
		.container-holder {
			.column {
			}
		}
	}
}

/* 	content-section.block
    ========================================================================== */
.content-section.block {
	margin: ($grid-gutter-width) 0 ($grid-gutter-width * 3);
	@include media-breakpoint-up(sm) {
		margin: ($grid-gutter-width * 2) 0 ($grid-gutter-width * 3);
	}

	.container-one-column {
		.container-holder {
			.column {
				.collection.grid {
					.grid-items {
						.item {
							margin: 0 !important;
							@include media-breakpoint-up(lg) {
								@include make-col(4);
								margin: 0 0 ($grid-gutter-width) 0;
							}

							.card {
								background: transparent;
								border: none;
								border-radius: 0;
								box-shadow: none;

								&-image {
									img {
										height: 534px;
										object-fit: cover;
									}
								}

								&-body {
									padding: $grid-gutter-width / 2 !important;

									.card-caption {
										position: absolute;
										right: 0;
										bottom: 50px;
										left: 0;
										display: flex !important;
										height: 100%;
										align-items: flex-end;
										justify-content: center;

										.card-title {
											color: white;
											font: {
												size: $h3-font-size;
												family: $font-family-smooch-sans;
												weight: $font-weight-bold;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* 	block1
    ========================================================================== */
.block1 {
	background-color: $secondary;
	padding: ($grid-gutter-width * 2.66) 0;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: -38px;
		width: 100%;
		height: 25px;
		z-index: 2;
		background: url("/images/green-path-top.svg") top/cover;
		@include media-breakpoint-up(lg) {
			top: -40px;
			height: 40px;
		}
	}

	&::after {
		content: "";
		position: absolute;
		bottom: -38px;
		width: 100%;
		height: 25px;
		z-index: 2;
		background: url("/images/wave-yellow-top.svg") top/cover;
		@include media-breakpoint-up(lg) {
			bottom: -40px;
			height: 40px;
		}
	}

	.container-two-columns {
		&::before {
			content: "";
			position: absolute;
			top: -32px;
			left: 0;
			right: 0;
			width: 100%;
			height: 35px;
			background: url("/images/header-bottom.svg") top/cover;
			transform: scale(-1);
			@include media-breakpoint-up(sm) {
				height: 112px;
				top: -35px;
			}
		}

		.container-holder {
			position: relative;

			.column {
				&.one {
					@include make-col(12);
					margin: 0;

					@include media-breakpoint-up(sm) {
						@include make-col(5);
						padding-left: 30px;
						padding-right: 15px !important;
					}

					.resource-image {
						img {
							height: 350px;
							@include media-breakpoint-up(sm) {
								height: 540px;
								object-fit: cover;
							}
							@include media-breakpoint-up(lg) {
								margin: 0;
								padding-right: 0 !important;
							}
						}
					}
				}

				&.two {
					background-color: $chardonnay;
					padding: ($grid-gutter-width * 1.33) !important;
					right: 15px;
					left: 15px;
					max-width: 92%;
					@include media-breakpoint-up(sm) {
						position: absolute;
						left: auto;
						right: 30px;
						max-width: 100%;
						height: 100%;
						@include make-col(7);
					}
					@include media-breakpoint-up(lg) {
						padding: $grid-gutter-width * 2 !important;
					}

					.page-block {
						h1 {
							color: $secondary;
							font: {
								family: $font-family-smooch-sans;
								size: $h2-font-size;
								weight: $font-weight-bold;
							}
						}

						h5 {
							font-size: $font-size-base * 1.0625;
							font-family: $font-family-base;
							margin-bottom: $grid-gutter-width;
							max-width: 437px;
						}

						p {
							a {
								position: relative;
								color: $primary;
								font: {
									size: $font-size-base * 1.25;
									family: $font-family-smooch-sans;
									weight: $font-weight-bold;
								}

								&:hover {
									&::after {
										margin-left: $grid-gutter-width / 2;
									}
								}

								&::after {
									content: "\f0a9";
									position: absolute;
									top: 0;
									margin-left: $grid-gutter-width / 3;
									transition: 0.3s;
									font: {
										family: $font-awesome;
										size: $font-size-base * 1.0625;
										weight: $font-weight-base;
									}
								}
							}
						}

						h5,
						p {
							color: black;
						}
					}
				}
			}
		}
	}
}

/* 	block2
    ========================================================================== */
.block2.bg-dark {
	background-color: $secondary !important;
	padding: ($grid-gutter-width * 3.33) 0 ($grid-gutter-width * 2);
	margin-top: -1px;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		bottom: -38px;
		width: 100%;
		height: 25px;
		z-index: 2;
		background: url("/images/wave-yellow-top.svg") top/cover;
		@include media-breakpoint-up(lg) {
			bottom: -40px;
			height: 40px;
		}
	}

	.container-two-columns {
		.container-holder {
			.column {
				&.one {
					@include media-breakpoint-up(lg) {
						@include make-col(8);
						padding-right: 0 !important;
					}

					.page-block {
						h1 {
							color: $chardonnay;
							font-size: $h2-font-size;
							line-height: 1;
							@include media-breakpoint-up(lg) {
								font-size: $h1-font-size;
							}
						}
					}
				}

				&.two {
					@include media-breakpoint-up(lg) {
						@include make-col(4);
					}

					p {
						color: white;
						margin-top: 0;
						@include media-breakpoint-up(sm) {
							margin-top: $grid-gutter-width;
						}

						.btn-outline-primary {
							@extend .btn-primary;
						}
					}
				}
			}
		}
	}

	.container-one-column {
		margin-top: $grid-gutter-width * 1.66;

		.container-holder {
			.column {
				.slider.page-block {
					.owl-stage-outer {
					}

					.owl-nav,
					.owl-dots {
						display: none;
					}
				}
			}
		}
	}
}

/* 	block3
    ========================================================================== */
.block3 {
	background-color: $secondary;
	padding: ($grid-gutter-width * 3) 0 ($grid-gutter-width * 3.33);
	position: relative;

	&::after {
		content: "";
		position: absolute;
		bottom: -38px;
		width: 100%;
		height: 25px;
		z-index: 2;
		background: url("/images/green-path-top.svg") bottom/cover;
		@include media-breakpoint-up(lg) {
			bottom: -40px;
			height: 40px;
		}
	}

	.container-one-column {
		@include media-breakpoint-up(sm) {
			padding: 0 $grid-gutter-width;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: -35px;
			left: 0;
			right: 0;
			width: 100%;
			height: 60px;
			background: url("/images/path-brown-bottom.svg") top/cover;
			@include media-breakpoint-up(sm) {
				height: 115px;
			}
		}

		.container-holder {
			.column {
				.resource-image,
				img {
					width: 100%;

					img {
						height: 350px;
						object-fit: cover;
						@include media-breakpoint-up(sm) {
							height: 540px;
						}
					}
				}

				.page-block.title {
					h1 {
						content: "";
						display: block;
						position: absolute;
						top: 25%;
						left: 0;
						right: 0;
						z-index: 1;
						color: white;
						text-align: center;
					}
				}

				.page-block.wysiwyg {
					content: "";
					position: absolute;
					top: 47%;
					left: 0;
					right: 0;
					display: flex;
					justify-content: center;
					z-index: 1;
				}
			}
		}
	}
}

/* 	block4
    ========================================================================== */
.block4 {
	padding: ($grid-gutter-width * 1.33) 0;
	overflow: hidden;

	.container-two-columns {
		.container-holder {
			.column {
				.page-block {
					h1 {
						font-size: $h2-font-size;
						line-height: 1;
						margin-bottom: $grid-gutter-width * 1.33;
						@include media-breakpoint-up(lg) {
							font-size: $h1-font-size;
						}
					}
				}

				&.one {
					@include media-breakpoint-up(lg) {
						@include make-col(7);
					}

					.page-block.wysiwyg {
						p {
							&:first-child {
								font-size: $font-size-base * 1.0625;
								font-weight: $font-weight-bold;
							}
						}
					}

					a.page-block.link {
						@extend .btn-primary;
					}
				}

				&.two {
					@include media-breakpoint-up(lg) {
						@include make-col(5);
					}

					.page-block {
						margin-top: $grid-gutter-width;

						p {
							a {
								position: relative;
								color: $primary;
								font: {
									size: $font-size-base * 1.25;
									family: $font-family-smooch-sans;
									weight: $font-weight-bold;
								}

								&:hover {
									&::after {
										margin-left: $grid-gutter-width / 2;
									}
								}

								&::after {
									content: "\f0a9";
									position: absolute;
									top: 0;
									margin-left: $grid-gutter-width / 3;
									transition: 0.3s;
									font: {
										family: $font-awesome;
										size: $font-size-base * 1.0625;
										weight: $font-weight-base;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.partners {
		text-align: center;
		margin-top: $grid-gutter-width * 3;
		@include media-breakpoint-up(sm) {
			margin-top: 0;
		}

		img {
			width: 111px;
			height: 79px;
		}
	}
}

/* 	content-section
    ========================================================================== */
.content-section {
	margin: ($grid-gutter-width * 3) 0;

	p {
		em {
			font-style: normal;
		}
	}
}

/* 	bundle-overview-section
    ========================================================================== */
.bundle-overview-section {
	padding: ($grid-gutter-width * 3.33) 0 ($grid-gutter-width * 4);
}

/* 	bundle-detail-section
    ========================================================================== */
.bundle-detail-section {
	padding: ($grid-gutter-width * 3.33) 0 0;
}

/*booking-section*/
.booking-section {
	background-color: $hoki !important;
	padding: ($grid-gutter-width * 3) 0 ($grid-gutter-width * 4) !important;

	h2 {
		color: white;
	}
}

/* bundle-detail*/
&.bundle-detail.accommodation-detail, &.bundle-detail.blog-post-detail {
	.eyecatcher {
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100vw;
			height: 25px;
			z-index: 1;
			background: url("/images/path-white-top.svg") top/cover;
		}
	}

	.bundle-detail-section {
		position: relative;
		padding: ($grid-gutter-width * 3) 0 ($grid-gutter-width * 4);

		&::before {
			content: "";
			position: absolute;
			top: -25px;
			width: 100%;
			height: 25px;
			z-index: 2;
			background: url("/images/wave-brown-top.svg") top/cover;
			@include media-breakpoint-up(lg) {
				top: -30px;
				height: 40px;
			}
		}

		.container {
			&:first-of-type {
			}
		}

		.container-two-columns {
			.container-holder {
				.column {
					.back-to-overview-link {
						order: 1;
						margin-bottom: $grid-gutter-width / 1.5;

						.btn-back {
							color: $primary;

							&:hover {
								color: darken($color: $primary, $amount: 15%);
							}
						}
					}

					.subtitle {
						order: 3;

						h5 {
							font-size: $font-size-base * 1.0625;
							font-family: $font-family-base;
							color: $primary;
						}
					}

					.title {
						order: 2;

						h1 {
							font-size: $h3-font-size;
							color: $primary;
							@include media-breakpoint-up(sm) {
								font-size: $h2-font-size;
							}
						}
					}

					.properties {
						display: flex;
						order: 4;
						margin: ($grid-gutter-width / 3) 0 0;
						padding-left: 0;
						list-style-type: none;

						li {
							&::before {
								content: none;
							}

							&:not(:last-child) {
								margin-right: $grid-gutter-width / 2;
							}

							.label {
								margin-left: $grid-gutter-width / 6;
							}
						}
					}

					.wysiwyg {
						order: 5;
						margin-top: $grid-gutter-width / 1.5;
					}
				}
			}
		}
	}
}

/*service-overview*/
&.service-overview,
&.service-category-detail {
	.lead-section {
		position: relative;
		padding: ($grid-gutter-width * 2) 0;
		background-color: $secondary;

		&::after {
			content: none;
		}

		&::before {
			content: "";
			position: absolute;
			top: -55px;
			width: 100%;
			height: 25px;
			z-index: 1;
			background: url("/images/wave-brown-top.svg") top/cover;
			transform: scale(-1);
			@include media-breakpoint-up(lg) {
				top: -55px;
				height: 40px;
			}
		}

		.container-one-column {
			&::before {
				content: "";
				position: absolute;
				top: -24px;
				left: 0;
				right: 0;
				width: 100vw;
				height: 25px;
				z-index: 1;
				background: url("/images/path-brown-top.svg") top/cover;
			}

			&::after {
				content: none;
			}
		}
	}

	.lead-section + .bundle-overview-section {
		padding: ($grid-gutter-width / 3) 0 ($grid-gutter-width * 4);
		background-color: $secondary;
	}
}

/*service-category-detail*/
&.service-category-detail {
	.lead-section {
		&::after {
			content: "";
			position: absolute;
			bottom: -48px;
			width: 100%;
			height: 30px;
			z-index: 2;
			background: url("/images/wave-brown-top.svg") top/cover;
			@include media-breakpoint-up(lg) {
				bottom: -50px;
				height: 40px;
			}
		}

		.container-one-column {
			&::after {
				content: "";
				position: absolute;
				bottom: -34px;
				left: 0;
				right: 0;
				width: 100%;
				height: 35px;
				background: url("/images/path-brown-bottom.svg") bottom/cover;
				transform: none;
				// @include media-breakpoint-up(lg) {
				//     bottom: -35px;
				//     height: 35px;
				// }
			}
		}
	}
}

/*contact*/
&.contact {
	.lead-section {
		&::after {
			content: none;
		}

		.container-one-column {
			&::after {
				content: none;
			}
		}
	}

	.content-section {
		background-color: $hoki;
		margin: 0;
		padding-bottom: $grid-gutter-width * 4;

		.container-two-columns {
			.column {
				&.one {
					.page-block {
						h3 {
							color: white;
							font-size: $font-size-base;
							font-weight: $font-weight-bold;
							font-family: $font-family-base !important;

							&:nth-of-type(2) {
								margin-top: $grid-gutter-width * 1.66;
							}
						}

						p,
						table {
							&,
							a {
								color: white;
								font-size: $font-size-base;
							}
						}

						p {
							span {
								display: flex;
								margin-bottom: $grid-gutter-width / 3;
								text-decoration: none;
							}

							&:nth-of-type(2) {
								margin-bottom: 0;
							}

							&.contact {
								a {
									@extend .btn-outline-primary;
								}
							}
						}

						&.title {
							margin-top: $grid-gutter-width * 1.66;
						}
					}

					table {
						tbody {
							tr {
								td {
									padding-right: 20px;
								}
							}
						}
					}

					ul.vocabulary-term-list {
						margin: ($grid-gutter-width / 2) 0 0;
						display: flex;
						align-items: center;

						li {
							margin: 0 ($grid-gutter-width / 2) 0 0;

							a {
								color: white;

								span {
									display: none;
								}

								i {
									color: white;
									font-size: $h5-font-size;
								}

								&:hover {
									opacity: 0.7;
								}
							}
						}
					}
				}

				&.two {
					margin-top: $grid-gutter-width;
					@include media-breakpoint-up(sm) {
						margin-top: 0;
					}

					.page-block {
						h3 {
							color: white;
							font-size: 1.125rem;
							font-weight: $font-weight-base;
							font-family: $font-family-base;
							margin-bottom: $grid-gutter-width / 1.5;
						}
					}

					.form {
						background-color: white;
						box-shadow: 3px 3px 6px #00000029;
						border: none;
						border-radius: 0;

						&-control-label {
							font-size: $font-size-base * 1.125;
						}

						&-control {
							height: 30px;
							border-radius: 0;
							border-color: $smokey-grey;
						}

						textarea {
							height: auto !important;
						}

						a {
							color: $primary;

							&:hover {
								color: darken($color: $primary, $amount: 15%);
							}
						}

						.btn-primary {
							@extend .btn-outline-primary;
							width: 200px;

							&::after {
								right: 10px;
							}
						}
					}
				}
			}
		}
	}
}

/*theme-demo*/
&.theme-demo {
	.lead-section {
		&:first-of-type {
			background-color: transparent;
			padding-bottom: 0;
			margin-top: $grid-gutter-width * 3;
			@include media-breakpoint-up(xl) {
				margin-top: 0;
			}

			&,
			.container {
				&::before,
				&::after {
					content: none;
				}

				.container-holder {
					h1 {
						color: $hoki;
					}

					h5,
					p {
						color: $primary;
					}
				}
			}
		}

		.container-holder {
			max-width: 100%;
			margin: 0 - ($grid-gutter-width / 2);
			text-align: left;

			.column {
				p {
					max-width: 730px;
					margin: 0;
					font-size: $font-size-base * 1.0625;
					font-weight: $font-weight-bold;
				}

				h1 {
					font-size: $h2-font-size;
					@include media-breakpoint-up(lg) {
						font-size: $h1-font-size;
					}
				}

				h5 {
					font-size: 1.25rem !important;
				}
			}
		}
	}

	.lead-section.bg-dark {
		padding-top: $grid-gutter-width * 5;

		&,
		.container-one-column {
			&::after {
				content: none;
			}
		}
	}

	.content-section.bg-dark {
		margin-bottom: $grid-gutter-width * 5;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			bottom: -48px;
			width: 100%;
			height: 30px;
			z-index: 2;
			background: url("/images/wave-hoki-bottom.svg") top/cover;
			@include media-breakpoint-up(lg) {
				bottom: -35px;
				height: 40px;
			}
		}

		.container-two-columns {
			&::after {
				content: "";
				position: absolute;
				bottom: -35px;
				left: 0;
				right: 0;
				width: 100%;
				height: 40px;
				background: url("/images/path-hoki.svg") bottom/cover;
				transform: scale(-1);
				@include media-breakpoint-up(lg) {
					bottom: -15px;
					height: 75px;
				}
			}
		}
	}

	.content-section {
		&,
		&.bg-dark {
			ul {
				@extend .custom-list-extra;
			}

			.custom-list {
				li {
					padding-left: 20px;
				}
			}
		}
	}
}

.lead-section.bg-dark,
.content-section.bg-dark {
	background-color: $hoki !important;
}

/*one-column-page*/
&.one-column-page {
	.lead-section.bg-dark,
	.content-section {
		.container-one-column {
			.container-holder {
				.column {
					.page-block {
						.btn {
							margin: ($grid-gutter-width) 0;
						}
					}
				}
			}
		}
	}
}
