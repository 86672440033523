// headings
h1 {
	font-size: $h2-font-size;
	@include media-breakpoint-up(lg) {
		font-size: $h1-font-size;
	}
}

h1, h2, h3, h4, h5, h6 {
	color: $hoki;
	font: {
		family: $font-family-smooch-sans;
		weight: $font-weight-bold;
	}
}

p {
	font-size: 0.9375rem;
}

// img
img {
	max-width: 100%;
	height: auto;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s !important;
}

// form
.form {
	padding: 15px;
	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

/*Gallery*/
.gallery {
	&-item {
		.gallery-link, .gallery-caption {
			box-shadow: $box-shadow;
		}
	}
}

/*bg-dark*/
.bg-dark {
	a {
		color: white !important;

		&:hover {
			color: darken($color: white, $amount: 15%) !important;
		}
	}
}


ul, ol {
	list-style: initial;
}

&.bundle-detail {
	.form {
		@extend .container;
		margin-bottom: 85px;
	}

	.lead-section {
		.info {
			display: flex;
			flex-flow: row wrap;

			> div {
				flex: 0 0 100%;
				max-width: 100%;
			}

			.title {
				order: -1;
			}
		}

		.former-price, .discount-price {
			color: #fff;
		}

		.discount-price {
			font-weight: 600;
		}
	}
}
