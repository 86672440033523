// new base file - january 2022

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.search-book {
	@extend .clearfix;
}

.search-book .tommy-zeb-wrapper * {
	font-family: $font-family-base !important;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-left {
	background: $hoki;
	box-shadow: 3px 3px 6px #00000029;
	border-radius: 0;
	@include media-breakpoint-up(sm) {
		width: 33.33%;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-right {
	@include media-breakpoint-up(sm) {
		width: 66.66%;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result.ng-scope {
	background: $hoki;
	box-shadow: 3px 3px 6px #00000029;
	border-radius: 0;
	padding: $grid-gutter-width;
	display: flex;
	@include media-breakpoint-down(sm) {
		flex-wrap: wrap;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-left h1 {
	color: white;
	font-size: $h3-font-size;
	font-family: $font-family-smooch-sans !important;
	text-align: center;
	border-bottom: none;
	@include media-breakpoint-up(lg) {
		font-size: $h2-font-size;
	}
}

.search-book .tommy-zeb-wrapper * h2 {
	font-size: 18px;
	font-weight: 700;
	color: white;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-person-categories h2 {
	margin-top: 0 !important;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-btn {
	@extend .btn-primary;
	position: relative;
	background-color: transparent !important;
	border: 2px solid #fff !important;
	width: 199px;
	font-family: $font-family-smooch-sans !important;
	font-size: $font-size-base * 1.25;
	font-weight: $font-weight-bold;
	text-align: center;

	&::after {
		content: "\f0a9";
		position: absolute;
		top: 8px;
		right: 10px;
		margin-left: $grid-gutter-width / 3;
		transition: 0.3s;
		font: {
			family: $font-awesome;
			size: $font-size-base * 1.0625;
			weight: $font-weight-base;
		}
	}

	&:hover {
		box-shadow: $box-shadow;
		background-color: transparent;
		border-color: white;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding {
	margin-top: $grid-gutter-width / 3;
	color: white;
	font-size: 1.5rem;
	font-family: $font-family-smooch-sans !important;
	font-weight: $font-weight-bold;
	@include media-breakpoint-up(sm) {
		margin-top: 0;
	}
	@include media-breakpoint-up(xl) {
		font-size: $h3-font-size;
		margin-top: -($grid-gutter-width / 3);
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding {
	color: $white;
	font-weight: 700;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-date {
	border-bottom: none;
}

.search-book .tommy-zeb-wrapper .tommy-zeb-left select,
.tommy-zeb-wrapper .tommy-zeb-left input {
	color: $light-navyblue;
	background-color: white;
	border-radius: 0;
	font-size: $font-size-base_sm;
	font-weight: $font-weight-bold;
}

.tommy-zeb-results-label {
	@include media-breakpoint-up(sm) {
		position: absolute;
		top: -30px;
		left: 0;
		transform: translateX(-30%);
		margin-top: -30px;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
	border-radius: 0;
	min-width: 100%;
	@include media-breakpoint-up(sm) {
		max-width: 200px;
		height: 168px;
	}
}

.tommy-zeb-wrapper .tommy-zeb-result-details {
	padding: 0;
	@include media-breakpoint-up(sm) {
		padding: 0 ($grid-gutter-width / 1.5);
	}

	ul {
		li {
			&::before {
				color: white;
			}
		}
	}
}

.search-book .tommy-zeb-result-description ul {
	padding-left: $grid-gutter-width / 1.875;

	li {
		font-size: $font-size-base_sm;
		color: white;
	}
}

.search-book .tommy-zeb-wrapper .tommy-zeb-result {
	margin-bottom: $grid-gutter-width / 2;
}

.search-book .tommy-zeb-result-action {
	display: flex;
	justify-content: flex-end;
}

@media (min-width: 1200px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
		max-width: 250px;
	}
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-details {
		width: 520px;
	}
}

@media (min-width: 980px) and (max-width: 1199px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image img {
		margin-bottom: 15px;
	}
}

@media (min-width: 768px) and (max-width: 979px) {
	.search-book .tommy-zeb-wrapper .tommy-zeb-result-image {
		margin-right: 0;
	}
}


.tbs_overzicht h2 .tbs-accommodatie {
	color: #638995;
}

body.default #TommyBookingSupport .tbs-btn-success {
	width: 100% !important;
	max-width: 185px !important;
}

.tbs_persoonsgegevens_overzicht > .tbs-row:not(.tbs-hr-bottom) {
	background-color: #fff !important;
	margin-bottom: 0 !important;
}
