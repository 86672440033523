.image-slider {
    .container-one-column {
        .container-holder {
            .column {
                .slider {
                    min-height: 100%;
                    .owl-stage-outer {
                        .owl-stage {
                            .owl-item {
                                .item {
                                    .card {
                                        display: flex;
                                        flex-direction: row;
                                        border: none;
                                        box-shadow: none;
                                        &-image {
                                            img {
                                                width: 63px;
                                                height: 63px;
                                            }
                                        }
                                        &-body {
                                            padding: 0 ($grid-gutter-width / 2)!important;
                                            .card-caption {
                                                .card-title-link {
                                                    position: relative !important;
                                                    top: 0 !important;
                                                }
                                                .card-title {
                                                    font: {
                                                        size: $h5-font-size;
                                                        family: $font-family-smooch-sans;
                                                        weight: $font-weight-bold;
                                                    }
                                                }
                                                .card-description {
                                                    font-size: $font-size-base / 1.067;
                                                    max-width: 250px;
                                                }
                                                .card-title,
                                                .card-description {
                                                    color: $hoki;
                                                }
                                            }
                                            .card-buttons {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                                &:not(:last-child) {
                                    position: relative;
                                    &::after {
                                        content: "";
                                        border-right: 3px solid $hoki;
                                        height: 100%;
                                        width: 3px;
                                        position: absolute;
                                        right: 15px;
                                        top: -10px;
                                    }
                                }
                            }
                        }
                    }
                    .owl-dots,.owl-nav {
                        display: none;
                    }
                }
            }
        }
    }
}
